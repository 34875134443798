import React from 'react';
import { FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import moment from "moment";
import { getIn } from "formik";

const FormikNewDateTime = ({ id, fieldName, formik, minDate, maxDate, label, required, disabled, onlyDate, size, fullWidth,
    customStyle, handleBlur }) => { 
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleDateChange = (date) => {
        formik.setFieldValue(fieldName, date?.$d);
    };

    const handleError = (errorData) => {
        setError(errorData)
    }

    const handleBlurOnInput = () => {
        formik.setFieldTouched([fieldName], true);
    };

    const handleFieldClick = () => {
        setOpen(true);
    };

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case "minDate": {
                return 'Please select a date after ' + moment(dayjs(minDate).$d).format("DD/MM/YYYY")
            }
            case "minTime": {
                return 'Please select a time after ' + moment(dayjs(minDate).$d).format("hh:mm A")
            }
            case "maxDate": {
                return 'Please select a date before' + moment(dayjs(maxDate).$d).format("DD/MM/YYYY")
            }
            case "maxTime": {
                return 'Please select a time before ' + moment(dayjs(maxDate).$d).format("hh:mm A")
            }
            case "invalidDate": {
                return 'Your date is not valid'
            }
            default: {
                return 'Invalid Date';
            }
        }
    }, [error]);
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']} sx={{ overflow: 'visible', paddingTop: '0px' }}>
                <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
                {onlyDate ? <DatePicker
                    id={id}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    slotProps={{
                        textField: {
                            onBlur: () => handleBlurOnInput(),
                            variant: 'outlined',
                            error: (formik.touched[fieldName] && (Boolean(formik.errors[fieldName]) || Boolean(error))) ? true : false,
                            required: required,
                            fullWidth: fullWidth,
                            InputProps: {
                                readOnly: true,
                                onClick: handleFieldClick,
                                sx: {
                                    background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                                }
                            },
                            size: size,
                            onKeyDown: (e) => {
                                e.preventDefault();
                            },
                            style: { marginTop: '0px', minWidth: 'auto' },
                            helperText: (formik.touched[fieldName] && (formik.errors[fieldName] || error)) ?
                                (formik.errors[fieldName] ? formik.errors[fieldName] : errorMessage) : ''
                        },
                        field: { clearable: required ? false : true, onClear: () => formik.setFieldValue(fieldName, null) }
                    }}
                    onChange={handleDateChange}
                    value={getIn(formik.values, fieldName) && dayjs(getIn(formik.values, fieldName))}
                    format={"DD/MM/YYYY"}
                    minDate={minDate ? dayjs(minDate) : null}
                    inputProps={{ 
                        readOnly: true, "data-testid": id,
                        style: {
                            ...customStyle
                        } 
                    }}
                    disabled={disabled}
                    maxDate={maxDate ? dayjs(maxDate) : null}
                /> : <DateTimePicker
                    fullWidth
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    disabled={disabled}
                    variant="inline"
                    format="DD/MM/YYYY hh:mm A"
                    id="endTime"
                    value={formik.values[fieldName] && dayjs(formik.values[fieldName])}
                    minDateTime={minDate ? dayjs(minDate) : null}
                    maxDateTime={maxDate ? dayjs(maxDate) : null}
                    onChange={handleDateChange}
                    timeSteps={{ minutes: 1 }}
                    onError={handleError}
                    inputProps={{
                        "data-testid": id,
                        style: {
                            ...customStyle
                        }
                    }}
                    slotProps={{
                        textField: {
                            onBlur: () => handleBlurOnInput(),
                            variant: 'outlined',
                            error: (formik.touched[fieldName] && (Boolean(formik.errors[fieldName]) || Boolean(error))) ? true : false,
                            required: required,
                            fullWidth: fullWidth,
                            InputProps: {
                                readOnly: true,
                                onClick: handleFieldClick,
                                sx: {
                                    background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                                }
                            },
                            size: size,
                            onKeyDown: (e) => {
                                e.preventDefault();
                            },
                            style: { marginTop: '0px', minWidth: 'auto' },
                            helperText: (formik.touched[fieldName] && (formik.errors[fieldName] || error)) ?
                                (formik.errors[fieldName] ? formik.errors[fieldName] : errorMessage) : ''
                        },
                        field: { clearable: required ? false : true, onClear: () => formik.setFieldValue(fieldName, null) }
                    }}
                />}
            </DemoContainer>
        </LocalizationProvider>
    );
};

export { FormikNewDateTime };
