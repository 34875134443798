import React, { useEffect, useState }   from 'react';
import { Stack, Grid }                  from '@mui/material';
import { useTranslation }               from 'react-i18next';
import { FormikLabelTextField,
    FormikLabelMuiPhone }               from '../../../../../../common/formik-fields-components/FormikFieldComponents';
    import { SelectLabelController }    from "../../../../../../common/formik-fields-components/SelectLabelController";
import AddressDetail                    from '../../../../../address/AddressDetails';
import { IdentificationIndiType }       from '../../../../../../common/StaticVariables';
import YesNoSwitchController            from '../../../../../../common/formik-fields-components/yes-no-switch-controller/YesNoSwitchController';
import FCSearch                         from '../../../../../file-claim/fc-search/FCSearch';
import { ICMSAxiosInterceptor }         from '../../../../../../config/axios.interceptor';
import { setFormikValueWithLowerCase, 
    setFormikValueWithUpperCase }       from '../../../../../../common/utils/Utils';
import { AddressTypes, IdentificationType, PartyType } from '../../../../../../common/GenericCodes';

const AuthRepDetails = ({formik, isIndividual, caseId, resolutionApplicantId, raDetails, disabled, isEdit}) => {
    const { t } = useTranslation();
    const [searchPartyDetails, setSearchPartyDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const onSelectSubmit = (authRep) => {
        let identification;
        let address = authRep.addressResources.find(add => add.addressCd == AddressTypes.registerdWithIBBI.addressCd);
        if (authRep.partyCd == PartyType.INDIVIDUAL) {
            identification = authRep.identifications.find(id => id.identificationCd == IdentificationType.PAN);
        } else {
            identification = authRep.identifications[0];
        }

        setSearchPartyDetails({
            ...authRep,
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || '', 
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            identificationId: identification?.identificationId || '',
            identificationCd: identification?.identificationCd || '',
            identification: identification?.identification || ''
        });

        formik.setValues({
            authorizedRepresentativeId: null,
            partyId: authRep.partyId,
            partyNameId: authRep.partyNameId,
            identificationCd: identification?.identificationCd || '',
            name: authRep.name,
            identificationId: identification?.identificationId || '',
            identification: identification?.identification || '',
            emailId: authRep.emailId,
            email: authRep.recentEmail,
            partyCd: authRep.partyCd,
            phoneNumberId: authRep.phoneNumberId,
            phoneNumber: authRep.recentPhoneDetail?.nationalNumber,
            extension: authRep.recentPhoneDetail?.countryCode ? '+' + authRep.recentPhoneDetail?.countryCode : '+91',
            relationshipName: '',
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            sameAsRA: false,
        });
    }

    const getRADetails = () => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`case/${caseId}/ra/${resolutionApplicantId}`).then(response => {
            let address = response.addressResources.find(add => add.addressCd == AddressTypes.registerdWithIBBI.addressCd);
            
            setSearchPartyDetails({
                ...response,
                addressId: address?.addressId || '',
                addressCd: address?.addressCd || '', 
                addressLine1: address?.addressLine1 || '',
                addressLine2: address?.addressLine2 || '',
                cityOrTown: address?.cityOrTown || '',
                state: address?.state || '',
                postalCode: address?.postalCode || '',
                country: address?.country || 'India'
            });

            formik.setValues(prevState => ({
                ...prevState,
                partyId: response.partyId,
                partyNameId: response.partyNameId,
                identificationCd: response.identificationCd,
                name: response.name,
                identificationId: response.identificationId,
                identification: response.identification,
                emailId: response.emailId,
                email: response.email,
                partyCd: response.partyCd,
                phoneNumberId: response.phoneNumberId,
                phoneNumber: response.phoneDetail?.nationalNumber,
                extension: response.phoneDetail?.countryCode ? '+' + response.phoneDetail?.countryCode : '+91',
                addressId: address?.addressId || '',
                addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
                addressLine1: address?.addressLine1 || '',
                addressLine2: address?.addressLine2 || '',
                cityOrTown: address?.cityOrTown || '',
                state: address?.state || '',
                postalCode: address?.postalCode || '',
                country: address?.country ? address?.country : 'India',
            }))
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const handleSameAsRA = (isSameAsRA) => {
        if (isSameAsRA) {
            getRADetails();
        } else {
            formik.resetForm();
        }
    }

    const resetForm = () => {
        formik.resetForm();
        formik.validateForm();
    }

    return (
        <Stack width="100%" spacing={2} data-testid="auth_rep-fields">
            <Stack direction="row" justifyContent='center' alignItems='center' spacing={2} my={2}>
                {!formik.values.authorizedRepresentativeId && 
                    <FCSearch placeholder={'Search By Identification Type'} width={'40%'} 
                            isOnlyIndividual={true} onSelectSubmit={onSelectSubmit} onClear={resetForm}/>
                }
                {(!formik.values.authorizedRepresentativeId && isIndividual)&& 
                    <YesNoSwitchController
                        title={t('Is_Authorized_Person_Same_As_RA')}
                        fieldName="sameAsRA"
                        id="sameAsRA"
                        formik={formik}
                        onChange={handleSameAsRA}
                    />
                }
            </Stack>
            <Stack>
                <Grid container spacing={2} my={1}>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="name"
                            fieldName="name"
                            label={t("Name")}
                            formik={formik}
                            size="small"
                            required={true}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={(disabled && searchPartyDetails?.name) || !isEdit}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectLabelController
                            id="identificationCd"
                            fieldName="identificationCd"
                            label={t("Identification_Type")}
                            formik={formik}
                            required={true}
                            size="small"
                            menuItems={IdentificationIndiType}
                            handleBlur={formik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            handleChange={(event) => {formik.handleChange(event)}}
                            disabled={(disabled && searchPartyDetails?.identificationId) || !isEdit}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelTextField
                            id="identification"
                            fieldName="identification"
                            label={t("Identification")}
                            formik={formik}
                            size="small"
                            required={true}
                            handleChange={(event) => setFormikValueWithUpperCase(event, formik)}
                            handleBlur={formik.handleBlur}
                            disabled={(disabled && searchPartyDetails?.identificationId) || !isEdit}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelTextField
                            id="relationshipName"
                            fieldName="relationshipName"
                            label={t("Designation")}
                            formik={formik}
                            size="small"
                            required={true}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="email"
                            fieldName="email"
                            label={t("Email")}
                            size="small"
                            formik={formik}
                            required={true}
                            handleChange={(event) => setFormikValueWithLowerCase(event, formik)}
                            handleBlur={formik.handleBlur}
                            disabled={(disabled && searchPartyDetails?.email) || !isEdit}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelMuiPhone
                            id="phoneNumber"
                            fieldName="phoneNumber"
                            label={t("Mobile_Number")}
                            countryCodeName="extension"
                            size="small"
                            formik={formik}
                            required={true}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={(disabled && searchPartyDetails?.phoneNumber) || !isEdit}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Stack mt={2}>
                <AddressDetail addressTitle={t('Address_Details')} addressFormik={formik} required
                    isDisabled={(disabled && searchPartyDetails?.addressLine1) || !isEdit}/>
            </Stack>
        </Stack>
    );
}

export default AuthRepDetails;
