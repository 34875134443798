import React, { useState, useEffect, useRef }              from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  Stack
}                                                  from "@mui/material";
import Close                                       from '@mui/icons-material/Close';
import * as Yup                                    from 'yup';
import AuthorisationForAssignmentFields            from './AuthorizationForAssignmentFields';
import { ICMSAxiosInterceptor }                    from '../../../../../../config/axios.interceptor';
import ICMSLoader                                  from '../../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                                from '../../../../../../common/icms-snackbar/IcmsSnackbar';
import { useTranslation }                          from 'react-i18next';
import { useFormik }                               from 'formik';
import { ICMSButton }                              from '../../../../../../common/icms-styled-components/IcmsStyledComponents';
import moment                                      from 'moment';

const AuthorisationForAssignmentDetails = (props) => {
    const { afaRowDetails, isEdit, handleCloseAfa, isCreate, ipaIpRelationId } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show    : false,
        message : '',
        severity: ''
    })
    const prevValidFromRef = useRef();

    const afaValidationSchema = Yup.object({
        certificationNo: Yup.string().required(t("Certification_Number_is_Mandatory")),
        validFrom: Yup.date().required(t("Valid_From_is_Mandatory")),
        validTo: Yup.date().required(t("Valid_To_is_Mandatory"))
    })
    
    const afaFormik = useFormik({ 
        initialValues: {
            certificationNo: isCreate ? '' : afaRowDetails.data.certificateNumber ,
            ipaPartyId: isCreate ? '' : afaRowDetails.data.ibbiIpaId ,
            validFrom: isCreate ? null: afaRowDetails.data.startTime,
            validTo:  isCreate ? null : afaRowDetails.data.endTime,
            remarks: isCreate ? '' : afaRowDetails.data.description
        },
        validationSchema: afaValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true
    });

    const saveAFA = () => {
        setMessage({show: false, message: '', severity: ''});
        setLoading(true);
        const {certificationNo, validFrom, validTo, remarks} = afaFormik.values;
        let payload= {
            certificateNumber: certificationNo,
            startTime: validFrom,
            endTime: validTo,
            description: remarks
        }
        if(ipaIpRelationId){
            ICMSAxiosInterceptor.post(`/ip/afa/${ipaIpRelationId}`, payload).then(response => {
                if (response) {
                    handleCloseAfa({ show: true,
                        message: `${t("Successfully_Created_Authorization_for_Assignment")}`,
                        severity: 'success'
                    });
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setMessage({show: true, message: error.message, severity: 'error'});
            });
        } else {
            ICMSAxiosInterceptor.post(`/ip/afa`, payload).then(response => {
                if (response) {
                    handleCloseAfa({ show: true,
                        message: `${t("Successfully_Created_Authorization_for_Assignment")}`,
                        severity: 'success'
                    });
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setMessage({show: true, message: error.message, severity: 'error'});
            });
        }        
    }

    const updateAfa = () => {
        setLoading(true)
        const {certificationNo, ipaPartyId, validFrom, validTo, remarks} = afaFormik.values
        const payload = {
            certificateNumber: certificationNo,
            ibbiIpaId: ipaPartyId,
            startTime: validFrom,
            endTime: validTo,
            description: remarks
        }
        ICMSAxiosInterceptor.put(`/ip/afa/${afaRowDetails.data.ipAfaId}`, payload).then(response => {
            if (response) {
                handleCloseAfa({show: true, message: t("AFA_Updated_Successfully"), severity: 'success'});
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error'})
        })
    }

    // useEffect(() => {
    //     if (prevValidFromRef.current !== undefined && afaFormik.values.validFrom !== prevValidFromRef.current) {
    //         afaFormik.setFieldValue('validTo', moment(afaFormik.values.validFrom).add(1, 'year'));
    //     }
    //     prevValidFromRef.current = afaFormik.values.validFrom;
    // }, [afaFormik.values.validFrom]);

    
   return (
        <Dialog open={afaRowDetails.open} fullWidth maxWidth='lg'>
            <DialogTitle sx={{ display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                lineHeight: '0.6', 
                letterSpacing: '0.0075em', 
                padding: '6px 24px'}}
            >
                <Typography variant="h6" style={{ flexGrow: 1 }}> {t("AFA_Details")} </Typography>
                <IconButton id="close-afa" data-testid="close-afa" onClick={handleCloseAfa} color="error">
                    <Close color='error'/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ paddingTop: '1rem !important' }}>
                <Stack spacing={2} my={2}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                        <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                            <AuthorisationForAssignmentFields afaFormik={afaFormik} disabled={(!isCreate && !isEdit)} />
                        </Grid>
                    </Grid>
                </Stack>
            </DialogContent> 
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        {isCreate && <ICMSButton variant="contained" onClick={saveAFA} color="primary" data-testid="create" size='small'
                                sx={{ float: 'right', marginRight: '8px' }} disabled={!afaFormik.isValid || !afaFormik.dirty}
                        >
                            {t('Create')}
                        </ICMSButton>}
                        {isEdit && <ICMSButton variant="contained" onClick={updateAfa} disabled={!afaFormik.isValid || !afaFormik.dirty} color="primary" data-testid="create" size='small'
                            sx={{ float: 'right', marginRight: '8px' }}>
                            {t('Save')}
                        </ICMSButton>}
                        <ICMSButton variant="outlined" onClick={handleCloseAfa} color="primary" data-testid="cancel-create" size='small'
                                sx={{ float: 'right', marginRight: '8px' }}>
                            {(isCreate || isEdit) ? t('Cancel'): t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>
            </DialogActions>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && 
                <IcmsSnackbar show={message.show} severity={message.severity} message={message.message}
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: ''})}
                />
            }
        </Dialog>
    );
}

export default AuthorisationForAssignmentDetails;