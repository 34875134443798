import { FormLabel, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { Fragment } from "react";
import { BiSolidMessageError } from "react-icons/bi";
import { FaCheck } from "react-icons/fa6";
import { getIn } from "formik";

const TextAreaLabelController = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, minRows, required,textAlign,  handleBlur, maxRows, nestedFormik, placeholder,handleFocus, tooltip } = props;
    const handleKeyDown = (event) => {
        let data = nestedFormik ? getIn(formik.values, fieldName) : formik.values[fieldName];
        if (event.key === ' ' && (data === '' || data.endsWith(' '))) {
            event.preventDefault();
        }
    };
    
    return (
        <Stack spacing={0.1}>
            {label && <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2, textAlign:textAlign ? textAlign :'' }} required={required}>{label}</FormLabel>}
            <Tooltip title={tooltip ? tooltip : ''} arrow>
            <TextField
                fullWidth
                margin="normal"
                multiline
                id={id}
                size={size}
                name={fieldName}
                value={nestedFormik ? getIn(formik.values, fieldName) : formik.values[fieldName]}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                minRows={minRows}
                maxRows={maxRows}
                onFocus={handleFocus}
                onBlur={handleBlur}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                inputProps={{
                    "data-testid": id
                }}
                placeholder={placeholder ? placeholder : ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            {/* {formik.touched[fieldName] && !formik.errors[fieldName] && formik.values[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                        </InputAdornment>
                    ),
                    sx: {
                        background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                        // border: '1px solid #d8e1e7'
                    }
                }}
            />
            </Tooltip>
        </Stack>
    )
}

export { TextAreaLabelController }