import { AppBar, Grid, Stack, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PartyContactTypes, PartyContactTypesLocale} from '../../common/GenericCodes';
import { StyledTab, TabPanel } from '../../common/icms-styled-components/Tab';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import CaseContactDetails from './CaseContactDetails';

const tabProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const CASE_CONTACT_TYPES = [
    PartyContactTypes.REGISTERED_CONTACT,
    PartyContactTypes.CORRESPONDENCE_CONTACT,
    PartyContactTypes.PRIMARY_CONTACT
]

const CaseContactList = ({caseId, partyId, relationshipId, canEdit, setEnabledSteps, showActionsInBottom}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [tab, setTab] = useState(0);
    const [contacts, setContacts] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    const getContacts = async () => {
        setContacts([]);
        await ICMSAxiosInterceptor.get(`case/${caseId}/site/party/${partyId}/relationship/${relationshipId}`).then(response => {
            for(const contactType of CASE_CONTACT_TYPES) {
                let address = response.items.find(contact => contact.siteCd == contactType)
                if (address) {
                    setContacts(prevState => ([
                        ...prevState,
                        {
                            siteCd: address.siteCd,
                            siteLocale: address.siteLocale,
                            partyAddressId: address.partyAddressId,
                            addressLine1: address.addressLine1,
                            addressLine2: address.addressLine2,
                            cityOrTown: address.cityOrTown,
                            state: address.state,
                            postalCode: address.postalCode,
                            country: address.country,
                            partyEmailId: '',
                            email: address.email,
                            partyPhoneNumberId: '',
                            phoneNumber: address.phoneDetail?.nationalNumber || '',
                            extension: address.phoneDetail?.countryCode ? '+' + address.phoneDetail?.countryCode : '+91'
                        }
                    ]))
                } else {
                    setContacts(prevState => ([
                        ...prevState,
                        {
                            siteCd: contactType,
                            siteLocale: PartyContactTypesLocale[contactType],
                            addressId: '',
                            addressLine1: '',
                            addressLine2: '',
                            cityOrTown: '',
                            state: '',
                            postalCode: '',
                            country: 'India',
                            emailId: '',
                            email: '',
                            phoneNumberId: '',
                            phoneNumber: '',
                            extension: '+91'
                        }
                    ]))
                }
            }
            if (setEnabledSteps) {
                setEnabledSteps(prevState => ({
                    ...prevState,
                    contactDetails: response.items.length > 0
                }))
            }
        }).catch(error => {
            console.log('error', error);
        })
    }

    useEffect(() => {
        getContacts();
        return () => {}
    }, [])
    
    return (
        <Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Tabs value={tab} onChange={handleTabChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'rgb(196 151 104)',
                                    height: '6px',
                                    color: 'rgb(196 151 104)'
                                }
                            }}
                            aria-label="contact details tabs"
                        >
                            {
                                contacts.map((contact, index) => {
                                    return <StyledTab key={contact.siteCd}
                                        label={PartyContactTypesLocale[contact.siteCd]} {...tabProps(index)} sx={{minHeight: '50px'}}
                                        color='rgb(196 151 104)'/>
                                })
                            }
                        </Tabs>
                    </AppBar>
                    {contacts.map((contact, index) => {
                        return (
                            <TabPanel key={index} value={tab} index={index} dir={theme.direction} borderColor='rgb(196 151 104)'>
                                <CaseContactDetails contact={contact} canEdit={canEdit} caseId={caseId} partyId={partyId} 
                                    relationshipId={relationshipId} refresh={getContacts} showActionsInBottom={showActionsInBottom}/>
                            </TabPanel>
                        )
                    })}
                </Grid>
            </Grid>
        </Stack>
    )
}

export default CaseContactList